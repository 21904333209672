export default [
	{
		path: "/",
		component: () => import('@/layout/Pgaes.vue'),
		children: [
			{
				path: '/',
				component: () => import('@/pages/Index.vue'),
				meta: {title: '首页'},
				redirect:'/admin'
			}
		],
		beforeEnter: (to, from, next) => {
			next();
		}
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'Error',
		component: () => import('@/pages/Error.vue'),
		meta: {title: '错误'},
	}
]
