import AImage from "@/components/common/Image.vue"
import ALink from "@/components/common/Link.vue"
import APage from "@/components/common/Page.vue"
import AEmpty from "@/components/common/Empty.vue"
import AVideo from "@/components/common/Video.vue"

export const installComponentsViews = (app)=>{
	app.component('AImage',AImage);
	app.component('ALink',ALink);
	app.component('APage',APage);
	app.component('AEmpty',AEmpty);
	app.component('AVideo',AVideo);
}
