import {directiveInputBlur} from "@/directive/input-blur.js";
import {directiveClickOutside} from "@/directive/click-outside.js";
import {directiveScrollLock} from "@/directive/scroll-lock.js";
import {directiveMotion} from "@/directive/motion.js";

export const initDirective = (app)=>{
	directiveInputBlur(app);
	directiveClickOutside(app);
	directiveScrollLock(app);
	directiveMotion(app);
}
