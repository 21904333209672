import {isIos,isMobile} from "@/common/tool.js"

const inputBlur = {
	install:(app,option)=>{
		app.directive('input-blur', {
			mounted(el) {
				if(!isMobile() && !isIos()){
					return false;
				}
				el.onblur = ()=>{
					var toBottom = ()=>{
						var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
						window.scrollTo(0, Math.max(scrollHeight - 1, 0));
					}
					toBottom();
					setTimeout(function() {
						toBottom();
					}, 300);
					setTimeout(function() {
						toBottom();
					}, 500);
				}
			}
		})
	}
}

export const directiveInputBlur = (app)=>{
	app.use(inputBlur)
}
