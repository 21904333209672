<script setup>
import {useRouter} from "vue-router";
import {computed} from "vue";

const router = useRouter();

const props = defineProps({
	to:{
		type:String,
		default:""
	},
})
const is_out = computed(()=>{
	return typeof props.to === "string" && props.to.indexOf('http') === 0;
})
const toLink = ()=>{
	router.push(props.to);
}
</script>
<template>
	<a v-if="is_out" class="link" :href="to" target="_blank" rel="nofollow">
		<slot></slot>
	</a>
	<div v-else class="link" @click="toLink">
		<slot></slot>
	</div>
</template>

<style lang="scss" scoped>
.link{display: block;cursor: pointer;}
</style>
