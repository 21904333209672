<script setup>
import { nextTick, reactive} from "vue";
const state = reactive({
	video_info:{},
	play_status:false,
})

const init = (video_info)=>{
	state.video_info = video_info;
	if(state.video_info.src){
		nextTick(()=>{
			playVideo();
		})
	}
}
const playVideo = ()=>{
	var video = document.getElementById('video_float');
	video.addEventListener('play',()=>{
		state.play_status = true;
	})
	video.addEventListener('pause',()=>{
		state.play_status = false;
	})
	video.play();
}
const pauseVideo = ()=>{
	var video = document.getElementById('video_float');
	video.pause();
}
const hideVideo = ()=>{
	state.video_info = {}
}
defineExpose({init})
</script>
<template>
	<main v-if="state.video_info.src">
		<div class="box">
			<div class="mark" @click="hideVideo"></div>
			<div class="content">
				<div class="title-box">
					<div class="element-more title">{{state.video_info.title}}</div>
					<div class="close" @click="hideVideo"></div>
				</div>
				<div class="video-box">
					<video class="video" :controls="state.play_status" x5-video-player-type="h5-page" webkit-playsinline="true" playsinline="true" :poster="state.video_info.cover" :id="'video_float'" loop @click.prevent="pauseVideo" >
						<source :src="state.video_info.src">
					</video>
					<div class="mark" v-if="!state.play_status">
						<div class="play" @click="playVideo"></div>
					</div>
				</div>
				<div class="close2" @click="hideVideo"></div>
			</div>
		</div>
	</main>
</template>
<style lang="scss" scoped>
.box{position: fixed;top:0;left:0;width: 100%;height:100%;z-index: $z-dialog;
	.mark{position: absolute;top:0;left:0;width: 100%;height:100%;background: rgba(0,0,0,.8)}
	.content{width:850px;position:absolute;top:50%;left:50%;transform: translate(-50%,-50%);
		.title-box{height:50px;overflow: hidden;background: $main;color: #fff;padding: 0 15px;display: flex;align-items: center;justify-content: space-between;
			.title{font-size: 16px;flex: 1;}
			.close{width: 50px;height:50px;background: url("@/assets/image/components/close.png") no-repeat center center;background-size: 20px; cursor: pointer;display: none;}
		}
		.video-box{height:480px;position: relative;background:#000;
			video{width: 100%;height:100%;object-fit: contain;outline: none;}
			.mark{position: absolute;top:0;left:0;width: 100%;height:100%;background: rgba(0,0,0,.5);
				.play{width:70px;height:70px;position: absolute;top:50%;left:50%;background: url("@/assets/image/components/player.png") no-repeat center center;background-size:contain;margin-left: -35px;margin-top: -35px; cursor: pointer;border-radius: 50%;}
			}
		}
		.close2{width:50px;height:50px;margin:30px auto 0;border-radius: 50%;background: url("@/assets/image/components/close.png") no-repeat center center;background-size:contain;cursor: pointer;}
	}
}
@media screen and (max-width: 992px) {
	.box{background: #000;
		.content{width: 100%;height:100%;
			.title-box{position: absolute;top:0;left:0;width: 100%;z-index: #{$z-dialog+1};padding:15px;
				.title{font-size: 16px;}
				.close{display: block;width:40px;height:30px;background-size:30px;}
			}
			.video-box{position: absolute;top:50%;left:50%;width: 100%;transform: translate(-50%,-50%);}
			.close2{display: none;}
		}
	}
}
</style>

