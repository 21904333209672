import {createApp} from 'vue'
import App from '@/App.vue'
import {router} from "@/router/index.js"
import {pinia} from "@/store/pinia.js"
import {initPlugins} from "@/plugins/index.js";
import {initDirective} from "@/directive/index.js";

const app = createApp(App)
app.use(pinia).use(router)

initPlugins(app);
initDirective(app);

app.mount('#app')

