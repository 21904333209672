import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

const scrollLock = {
	install: ( app, options ) => {
		app.directive('scroll-lock', {
			mounted( el, binding ) {
				if (binding.value) {
					disableBodyScroll(el)
				}
			},
			updated( el, binding ) {
				if (binding.value) {
					disableBodyScroll(el)
				} else {
					enableBodyScroll(el)
				}
			},
			unmounted( el ) {
				enableBodyScroll(el)
			}
		})
	},
}

export const directiveScrollLock = (app)=>{
	app.use(scrollLock)
}
