const getDirection = (startPoint, endPoint) => {
	//计算终点与起点的差值
	var x = endPoint.clientX - startPoint.clientX;
	var y = endPoint.clientY - startPoint.clientY;
	//根据正切数值计算滑动角度
	var arg = Math.atan2(Math.abs(y), Math.abs(x))
	arg = Math.ceil(arg * 180 / Math.PI)
	//设置滑动距离的参考值
	var d = 10;
	var direction = false;
	if (Math.abs(x) && arg <= 45) {
		if (x > 0) {
			direction = 'right';
		} else {
			direction = 'left';
		}
	}
	if (Math.abs(y) > d && arg > 45) {
		if (y > 0) {
			direction = 'bottom';
		} else {
			direction = 'top';
		}
	}
	return direction;
}

const motion = {
	install:(app,option)=>{
		app.directive('motion', {
			bind(el, binding) {
				var startPoint = null;
				el.addEventListener('touchstart', (e) => {
					startPoint = e.touches[0];
				});
				el.addEventListener('mousedown', (e) => {
					startPoint = e;
				});
				el.addEventListener('touchmove', (e) => {

				});
				el.addEventListener('mousemove', (e) => {

				});
				el.addEventListener('touchend', (e) => {
					var endPoint = e.changedTouches[0];
					binding.value(getDirection(startPoint, endPoint))
				});
				el.addEventListener('mouseup', (e) => {
					binding.value(getDirection(startPoint, e))
				});
			}
		})
	}
}
export const directiveMotion = (app)=>{
	app.use(motion)
}
