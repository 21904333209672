import {createRouter, createWebHashHistory} from "vue-router"
import _ from "lodash";

const createRouteName = (route)=>{
	let list = route.default || route
	return list.map((item,index)=>{
		var path = item.path.split('/');
		if(path && path.length > 0){
			path.map((v,i)=>{
				path[i] = _.capitalize(v);
			})
		}
		item.name = path.join('');
		if(item.children){
			item.children = createRouteName(item.children)
		}
		return item;
	})
}
const createRoute = ()=>{
	let routes = [];
	let routerContext = import.meta.globEager('./modules/**/*.js')
	for(let key in routerContext){
		let routerModule = createRouteName(routerContext[key]);
		routes = [...routes, ...(routerModule.default || routerModule)]
	}
	return routes;
}

export const router = createRouter({
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { top: 0, left: 0 }
		}
	},
	history: createWebHashHistory(),
	routes:createRoute()
})
