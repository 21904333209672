import {getCookie} from "@/common/tool.js";

export default [
	{
		path: "/admin",
		component: () => import('@/layout/admin/Admin.vue'),
		children: [
			{
				path: '/admin',
				component: () => import('@/pages/admin/dashboard/Index.vue'),
				meta: {title: '控制台'},
			},
			{
				path: '/admin/article',
				component: () => import('@/pages/admin/article/Index.vue'),
				meta: {title: '文章管理'},
			},
			{
				path: '/admin/project',
				component: () => import('@/pages/admin/project/Index.vue'),
				meta: {title: '项目管理'},
			},
			{
				path: '/admin/form',
				component: () => import('@/pages/admin/form/Index.vue'),
				meta: {title: '表单管理'},
			},
			{
				path: '/admin/user',
				component: () => import('@/pages/admin/user/Index.vue'),
				meta: {title: '用户管理'},
			},
			{
				path: '/admin/admin',
				component: () => import('@/pages/admin/admin/Index.vue'),
				meta: {title: '管理员'},
			},
			{
				path: '/admin/config',
				component: () => import('@/pages/admin/config/Index.vue'),
				meta: {title: '系统配置'},
			},
			{
				path: '/admin/config/manage',
				component: () => import('@/pages/admin/config/manage/Index.vue'),
				meta: {title: '配置管理'},
			},
			{
				path: '/admin/:pathMatch(.*)*',
				name: 'Error',
				component: () => import('@/pages/admin/Error.vue'),
				meta: {title: '错误'},
			}
		],
		beforeEnter: (to, from, next) => {
			if (!getCookie('authorization')) {
				next('/admin/auth/login')
			} else {
				next();
			}
		}
	},
	{
		path: '/admin/auth/login',
		component: () => import('@/pages/admin/auth/Login.vue'),
		meta: {title: '登录'},
	},
]
