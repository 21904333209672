const clickOutside = {
	install:(app,option)=>{
		app.directive('click-outside', {
			mounted(el,binding, vnode) {
				const clickHandler = (e) =>{
					if (el.contains(e.target)) {
						return false;
					}
					if (binding.value) {
						binding.value(e);
					}
				}
				el.__vueClickOutside__ = clickHandler;
				document.addEventListener('click', clickHandler);
			},
			unmounted(el){
				document.removeEventListener('click', el.__vueClickOutside__);
				delete el.__vueClickOutside__;
			}
		})
	}
}
export const directiveClickOutside = (app)=>{
	app.use(clickOutside)
}
